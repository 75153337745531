// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-changelog-js": () => import("./../../../src/templates/changelog.js" /* webpackChunkName: "component---src-templates-changelog-js" */),
  "component---src-templates-command-js": () => import("./../../../src/templates/command.js" /* webpackChunkName: "component---src-templates-command-js" */),
  "component---src-templates-commands-js": () => import("./../../../src/templates/commands.js" /* webpackChunkName: "component---src-templates-commands-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

